import { useEffect, useState } from "react";
import { WorkflowTypeApi } from "../../../../../app/modules/apis/workflowTypes.api";
import { SidebarMenuItem } from "./SidebarMenuItem";

// Define the type for workflow items
interface WorkflowItem {
  wft_id: number;
  wft_name: string;
  wft_link_organization: string;
  wft_link_subscription: string;
  wft_link_provider: string;
  wft_visible_to_client: string;
}

const API = new WorkflowTypeApi();

const WftMenuItems = () => {
  // Use the defined type for state
  const [workFlowTypeItems, setWorkFlowTypeItems] = useState<WorkflowItem[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchWorkflowTypes = async () => {
      try {
        let response = await API.pullAllWorkflowTypes("wft_visible_to_client=Yes");
        console.log("API Response:", response); // Debugging the response
        if (response.status && Array.isArray(response.data)) {
          setWorkFlowTypeItems(response.data);
        } 
      } catch (error) {
        console.error("Error fetching workflow types:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchWorkflowTypes();
  }, []);

  if (loading) {
    return <div><i>Loading...</i></div>;
  }

  return (
    <>
      {workFlowTypeItems.map((item) => (
        <SidebarMenuItem
          key={item.wft_id} // Add unique key
          to={`/workflow/requests/${item.wft_id}`}
          title={item.wft_name}
          icon="right"
          fontIcon=""
        />
      ))}
    </>
  );
};

export { WftMenuItems };
