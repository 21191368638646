import { httpGet, httpPost, httpPut } from "../auth/core/_requests";

export class WorkflowTypeApi {
  constructor() {}
  private url: string = "workflow/types";
  createWorkflowType = async (data: any) => {
    let results = await httpPost(this.url, data);
    return this.extractWorkflowType(results);
  };

  //update WorkflowType
  updateWorkflowType = async (id: number | string, data: any) => {
    let results = await httpPut(`${this.url}/${id}`, data);
    return this.extractWorkflowType(results);
  };

  //Get all WorkflowType
  pullAllWorkflowTypes = async (params?: string) => {
    let results = await httpGet(`${this.url}${params ? "?" + params : ""}`);
    return this.extractWorkflowType(results);
  };

  //Get one WorkflowType
  getOneWorkflowType = async (id: number | string) => {
    let results = await httpGet(`${this.url}/${id}`);
    return this.extractWorkflowType(results);
  };

  extractWorkflowType = (results: any) => {
    if (results.data && results.status) {
      return results.data;
    } else {
      return false;
    }
  };
}
