import { lazy, FC, Suspense } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { MasterLayout } from "../../_metronic/layout/MasterLayout";
import TopBarProgress from "react-topbar-progress-indicator";
import { DashboardWrapper } from "../pages/dashboard/DashboardWrapper";
// import {MenuTestPage} from '../pages/MenuTestPage'
import { getCSSVariableValue } from "../../_metronic/assets/ts/_utils";
import { WithChildren } from "../../_metronic/helpers";
// import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
//import {Subscriptions} from '../pages/Subscriptions'

const PrivateRoutes = () => {
  // const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  // const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  // const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  // const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  // const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))
  // const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))

  const SubscriptionsPage = lazy(
    () => import("../modules/profile/SubscriptionsPage")
  );
  const StatementsPage = lazy(
    () => import("../modules/profile/StatementsPage")
  );
  const InvoicingPage = lazy(() => import("../modules/profile/InvoicingPage"));
  const WorkflowPage = lazy(() => import("../modules/profile/WorkflowPage"));
  const OrdersPage = lazy(() => import("../modules/profile/OrdersPage"));
  const PeoplePage = lazy(() => import("../modules/profile/PeoplePage"));
  const TrackingPortal = lazy(
    () => import("../modules/profile/TrackingPortalPage")
  );
  const IncidentManagement = lazy(
    () => import("../modules/profile/IncidentManagementPage")
  );
  const SensorDashboard = lazy(
    () => import("../modules/profile/SensorDashboardPage")
  );
  const LogisticsUploader = lazy(
    () => import("../modules/profile/LogisticsUploaderPage")
  );
  const GeofenceMonitoring = lazy(
    () => import("../modules/profile/GeofenceMonitoringPage")
  );
  const BiAnalytics = lazy(() => import("../modules/profile/BiAnalyticsPage"));
  const CustomerServicePage = lazy(
    () => import("../modules/profile/CustomerServicePage")
  );
  const UnitsProvidersPage = lazy(
    () => import("../modules/profile/UnitsProvidersPage")
  );

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path="auth/*" element={<Navigate to="/dashboard" />} />
        {/* Pages */}
        {/* <Route path='/subscriptions' element={<Subscriptions />} /> */}
        <Route
          path="trackingportal"
          element={<Navigate to="/tracking/portal" />}
        />
        <Route path="analytics" element={<Navigate to="/error/500" />} />
        <Route path="tools" element={<Navigate to="/error/404" />} />
        <Route path="dashboard" element={<DashboardWrapper />} />

        {/* <Route path='builder' element={<BuilderPageWrapper />} />
        <Route path='menu-test' element={<MenuTestPage />} /> */}

        {/* Lazy Modules */}

        <Route
          path="subscriptions/*"
          element={
            <SuspensedView>
              <SubscriptionsPage />
            </SuspensedView>
          }
        />
        <Route
          path="statements/*"
          element={
            <SuspensedView>
              <StatementsPage />
            </SuspensedView>
          }
        />
        <Route
          path="invoicing/*"
          element={
            <SuspensedView>
              <InvoicingPage />
            </SuspensedView>
          }
        />
        <Route
          path="workflow/*"
          element={
            <SuspensedView>
              <WorkflowPage />
            </SuspensedView>
          }
        />
        <Route
          path="orders/*"
          element={
            <SuspensedView>
              <OrdersPage />
            </SuspensedView>
          }
        />
        <Route
          path="people/*"
          element={
            <SuspensedView>
              <PeoplePage />
            </SuspensedView>
          }
        />
        <Route
          path="tracking/*"
          element={
            <SuspensedView>
              <TrackingPortal />
            </SuspensedView>
          }
        />
        <Route
          path="tools/incident/*"
          element={
            <SuspensedView>
              <IncidentManagement />
            </SuspensedView>
          }
        />
        <Route
          path="tools/sensor/*"
          element={
            <SuspensedView>
              <SensorDashboard />
            </SuspensedView>
          }
        />
        <Route
          path="tools/logistics/*"
          element={
            <SuspensedView>
              <LogisticsUploader />
            </SuspensedView>
          }
        />
        <Route
          path="tools/geofence/*"
          element={
            <SuspensedView>
              <GeofenceMonitoring />
            </SuspensedView>
          }
        />
        <Route
          path="bi/*"
          element={
            <SuspensedView>
              <BiAnalytics />
            </SuspensedView>
          }
        />
        <Route
          path="customer/*"
          element={
            <SuspensedView>
              <CustomerServicePage />
            </SuspensedView>
          }
        />
        <Route
          path="units/*"
          element={
            <SuspensedView>
              <UnitsProvidersPage />
            </SuspensedView>
          }
        />

        {/* Page Not Found */}
        <Route path="*" element={<Navigate to="/error/404" />} />
      </Route>
    </Routes>
  );
};

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue("--bs-primary");
  TopBarProgress.config({
    barColors: {
      "0": baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  });
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>;
};

export { PrivateRoutes };
