import { useIntl } from "react-intl";
import { MenuItem } from "./MenuItem";
import { MenuInnerWithSub } from "./MenuInnerWithSub";
import { useAuth } from "../../../../../app/modules/auth";

export function MenuInner() {
  const intl = useIntl();
  const userData: any = useAuth();
  //console.log('userData', userData);
  // const tools = userData?.auth?.client_tools?.map(
  //   (obj: any) => Object.keys(obj)[0]
  // );
  const tools = userData?.auth?.client_tools ? Object.keys(userData.auth.client_tools) : [];
  console.log('tools', tools);

  return (
    <>
      <MenuItem
        title={intl.formatMessage({ id: "MENU.DASHBOARD" })}
        to="/dashboard"
      />
      {tools.includes("Tracking Portal") ? (
        <MenuItem title="Tracking Portal" to="/tracking/portal" />
      ) : null}
      {tools.includes("BI Analytics") ? (
        <MenuItem title="BI Analytics" to="/bi/analytics" />
      ) : null}

      {tools.includes("Incident Management Alerts") ||
      tools.includes("Sensor Dashboard") ||
      tools.includes("Logistics Uploader") ||
      tools.includes("Geofence Monitoring") ? (
        <MenuInnerWithSub
          title="Tools"
          to="/tools/"
          menuPlacement="bottom-start"
          menuTrigger="hover"
          hasArrow={true}
          isMega={true}
        >
          {/* PAGES */}
          {tools.includes("Incident Management Alerts") ? (
            <MenuItem
              // hasBullet={true}
              to="/tools/incident/management"
              title="Incident Management Alerts"
              textColor="black"
              bulletColor="black"
              iconColor="black"
              icon="notification-on"
            />
          ) : null}
          {tools.includes("Sensor Dashboard") ? (
            <MenuItem
              // hasBullet={true}
              to="/tools/sensor/sensordashboard"
              title="Sensor Dashboard"
              textColor="black"
              bulletColor="black"
              icon="more-2"
            />
          ) : null}

          {tools.includes("Logistics Uploader") ? (
            <MenuItem
              // hasBullet={true}
              to="/tools/logistics/uploader"
              title="Logistics Uploader"
              textColor="black"
              bulletColor="black"
              icon="logistic"
            />
          ) : null}
          {tools.includes("Geofence Monitoring") ? (
            <MenuItem
              // hasBullet={true}
              to="/tools/geofence/monitoring"
              title="Geofence Monitoring"
              textColor="black"
              bulletColor="black"
              icon="map"
            />
          ) : null}
        </MenuInnerWithSub>
      ) : null}

      {/* <MenuItem title='Layout Builder' to='/builder' /> */}
      {/* <MenuItem title='Subscriptions' to='/subscriptions' /> */}
    </>
  );
}
